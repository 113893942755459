import Vue from 'vue'
import VueRouter from 'vue-router'
import paths from './paths'
import axios from "axios";
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: paths,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
})
function checkTime(redirect, to, from, next)
{
  let time_set = JSON.parse(localStorage.getItem('storage_time'));
  if(!time_set || typeof time_set == 'undefined')
  {
    localStorage.removeItem("access_token");
    if(redirect === true)
    {
      next({
        path: '/auth',
        params: { nextUrl: to.fullPath }
      })
    }
  } else {
    /**
     * time_set = {
                    refresh_hours: 2,
                    refresh_max:3,
                    max_hours: 6,
                    current_refresh_count:0,
                    timestamp: new Date().getTime()
                };
     * @type {Timestamp | number}
     */
    let dateString = time_set.timestamp;
    let now = new Date().getTime().toString();
    let diff =(now - dateString) / 1000;
    diff /= (60 * 60);
    let compare_hours = Math.abs(Math.round(diff));
    if(compare_hours >= time_set.max_hours || time_set.current_refresh_count >= time_set.refresh_max)
    {
      /**
       * If less that max hours allowed based on 2h refresh
       * or it has passed the max number of refreshes 3
       * TODO: instead of logout show a popup login box
       */
      localStorage.removeItem("access_token");
      if(redirect === true)
      {
        next({
          path: '/auth',
          params: { nextUrl: to.fullPath }
        });
        console.log("Out of time ");
      }
    } else {
      /**
       * check compare is less than refresh hour rate
       */
      if(compare_hours >= time_set.refresh_hours)
      {
        console.log("Token Needs to be refreshed");
        /**
         * time has passed the refresh mark so update the token
         * TODO: add to method in mutations and call it
         */
        axios.post(store.state.endpoints.refreshJWT, {
          token: localStorage.getItem('access_token')
        })
            .then((response)=>{
              let data = response.data;
              localStorage.setItem('access_token', data.token);
              store.state.accessToken = data.token;
              let time_obj = {
                refresh_hours: 2,
                refresh_max:3,
                max_hours: 6,
                current_refresh_count:(dateString.current_refresh_count + 1),
                timestamp: new Date().getTime()
              };
              localStorage.setItem('storage_time', JSON.stringify(time_obj) );
              localStorage.setItem('user', JSON.stringify(data.user));
            })
            .catch((error)=>{
              /***
               * Code is completly out of time and cannot be refreshed
               * remove authcode and redirect to login
               * TODO: Add popup login component
               */
              localStorage.removeItem("access_token");
              if(redirect === true)
              {
                next({
                  path: '/auth',
                  params: { nextUrl: to.fullPath }
                });
                console.log("Out of time ");
              }
              console.log(error);
            })
      }
    }
  }
}

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('backend_absolute_url') == null) {
    let backend_absolute_url = axios.defaults.baseURL;
    localStorage.setItem('backend_absolute_url', backend_absolute_url)
  }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('access_token') == null) {
      next({
        path: '/auth',
        params: { nextUrl: to.fullPath }
      })
    } else {
      // Step One: Get the Subscriber and get the Subscriber groups
      let user = JSON.parse(localStorage.getItem('user'));

      //TODO: remove before live
      checkTime(true, to, from, next);
      let user_groups = user.groups;
      // record.meta.authGroups
      if (to.matched.some(record => record.meta.authGroups))
      {
        let required_groups = to.matched.slice().reverse().find(r => r.meta && r.meta.authGroups).meta.authGroups;
        let matching_groups = user_groups.filter(arr1Item => required_groups.includes(arr1Item)); //returns array of items in the array
        if(matching_groups.length) {
          next();
        }
        else
        {
          //todo: add an error with Subscriber ID and link to the developer logs
          console.log("Error access denied");
          console.log("Group auth: required:[" + required_groups.toString() + "] matching:[" + matching_groups.toString()+"]");
          next({
            path: '/errors/401',
            params: { nextUrl: to.fullPath }
          })
        }
      } else {
        //if no group is required return to the page
        next()
      }
    }
  } else {
    //No Auth Required
    checkTime(false, to, from, next);
    if(to.path === '/auth/login' && localStorage.getItem('access_token'))
    {
      console.log("Logged In Already Will Redirect to Dashboard");
      next({
        path: '/c-panel/matters',
        params: { nextUrl: to.fullPath }
      });
      return false;
    }
    if(to.path === '/auth/logout' && !localStorage.getItem('access_token'))
    {
      console.log("Logout reached but no Subscriber is logged in, will redirect to login");
      next({
        path: '/auth/login',
        params: { nextUrl: to.fullPath }
      });
    }
    console.log('Run Next Path');
    next();
  }
});



export default router
