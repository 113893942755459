<template>
  <v-app v-cloak>
    <v-banner
        v-show="showBanner"
        color="red lighten-2"
        dark
        class="fixed-banner"
        icon="mdi-alert-circle-outline"
        elevation="2"
    >
      <template v-slot:actions>
        <v-btn text @click="showBanner = false">Close</v-btn>
      </template>
      Due to technical issues, our office's internet and phone lines are currently unavailable. For assistance, please contact us on mobile at <strong>07517 904595</strong>.
    </v-banner>
    <notifications group="notifications" :position="'top right'"></notifications>
    <core-view></core-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    showBanner: true, // Controls visibility of the banner
  }),
};
</script>

<style>
/* Styles for the fixed banner */
.fixed-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
