<template>
  <div :class="{'pt-12 pb-12': !dense}" class="">
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
      <div :class="{'mb-5': !dense}">
        <v-text-field
            required
            :dense="dense"
            :dark="isDark"
            :outlined="true"
            :rules="nameRules"
            :label="labels === true ? 'Name': ''"
            v-model="contact.name">

        </v-text-field>
      </div>


      <div :class="{'mb-5': !dense}">
        <v-text-field
            required
            v-if="showPhone"
            :dense="dense"
            :dark="isDark"
            :rules="phoneRules"
            :outlined="true"
            :label="labels === true ? 'Phone': ''"
            v-model="contact.phone">

        </v-text-field>
      </div>

      <div :class="{'mb-5': !dense}">
       <v-text-field
           required
           :dark="isDark"
           :dense="dense"
           :rules="emailRules"
           :outlined="true"
           :label="labels === true ? 'Email': ''"
           v-model="contact.email">

       </v-text-field>
     </div>

      <div :class="{'mb-5': !dense}">
        <v-textarea
            required
            :dark="isDark"
            :dense="dense"
            :rules="messageRules"
            :outlined="true"
            :label="labels === true ? 'Message': ''"
            v-model="contact.message">
        </v-textarea>
      </div>
    </v-form>

    <v-alert class="mt-5 mb-5" v-if="sent_message" :type="has_errors === false ? 'success': 'error'">
      {{ message }}
    </v-alert>

    <v-btn block @click="submitForm()" color="grey darken-4 white--text">
      Send
    </v-btn>
  </div>
</template>

<script>

import axios from "axios"

  export default {
    props: {
      labels: Boolean,
      isDark: Boolean,
      dense: Boolean,
      showPhone: Boolean
    },
    data: () => ({
      item: 1,
      contact: {
        name: '',
        email: '',
        phone: null,
        message: '',

      },
      sent_message: false,
      has_errors: false,
      message: '',

      valid: true,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2) || 'Name must be more than 10 characters',
      ],
      phoneRules: [
        v => !!v || 'Phone is required',
        v => (v && v.length >= 11) || 'Name must be less than 11 characters',
      ],
      messageRules: [
        v => !!v || 'Message is required',
        v => (v && v.length >= 10) || 'Message must be more than 10 characters',
      ],
    }),
    created() {

    },
    watch: {

    },
    methods: {
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      submitForm() {
        if(this.$refs.form.validate())
        {
          let self = this;
          axios.post('/base/contact/',self.contact).then(function(r){
            self.has_errors = false;
            self.sent_message = true;
            self.message = 'Successfully sent message.';
            self.reset();
          }).catch(function(e){
            self.has_errors = true;
            self.sent_message = true;
            self.message = 'Error sending message.';
          });
        }
      }
    }
  }
</script>