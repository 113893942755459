import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const options = {
    theme: {
        themes: {
            light: {
                primary: '#7AB317',
                secondary: '#444444',
                accent: '#8c9eff',
                error: '#b71c1c',
                footer_title: '#FFFFFF',
                footer: '#999999'
            },
            dark: {
                primary: '#7AB317',
                secondary: '#444444',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
}
export default new Vuetify(options);
