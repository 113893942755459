<template>
  <v-card color="primary"
          class="pt-12 pb-12"
          style="border-radius:0px;">
    <v-container>
      <h1 class="text-h2 white--text font-weight-bold">
        {{ name }}
      </h1>
    </v-container>
  </v-card>
</template>

<script>
  export default {
    props: {
      name: String
    },
    data: () => ({
    }),
    created() {

    },
    watch: {

    },
    methods: {

    }
  }
</script>