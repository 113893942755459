<template>
  <div class="footer-wrapper">
    <v-card :flat="true" class="radius-0" color="primary" height="7">

    </v-card>
    <v-card
        color="blue-grey darken-4"
        class="elevation-1 ybr-footer radius-0 pt-12 pb-12">
      <v-row
          justify="center"
          style="max-width: 90%" class="ma-auto">
        <v-col
            v-for="item in footer_items"
            cols="12" :md="item.cols">
          <h3
              :class="{'text-left': item.align === 'left', 'text-md-center': item.align === 'center'}"
              class="font-weight-bold footer_title--text mb-3">
            {{ item.title }}
          </h3>
          <p v-for="text in item.text"
             v-text="text"
             class="footer--text body-2" v-if="item.text.length"></p>

          <ul class="footer-list pa-0" v-if="item.list.length">
            <li class="body-2 mb-2" v-for="li in item.list">
              {{ li }}
            </li>
          </ul>

          <div class="icon-list text-md-center" v-if="item.icons">
            <v-icon
                class="footer-icon"
                v-for="icon in item.icons"

                @click="openLink(icon.link)">
              {{ icon.icon }}
            </v-icon>
          </div>
          <main-small-contact :showPhone="false" :isDark="true" :labels="true" :dense="true" v-if="item.contact"></main-small-contact>
        </v-col>

      </v-row>
    </v-card>
    <v-card color="grey darken-4" class="radius-0">
      <v-card-text>
        <p class="body-2 footer--text mb-0">
          &copy; YorkshireBathResurfacing
        </p>
      </v-card-text>
    </v-card>
  </div>

</template>

<style lang="scss">
  .footer-list {
    margin:0;
    padding:0;
    li {
      display: block;
      padding: 0;
      margin: 0;
      color: #999;
      &:hover {
        color: #7AB317;
      }
    }
  }
  .footer-icon {
    color: #999!important;
    &:hover {
      color: #7AB317!important;
    }
  }

  .radius-0 {
    border-radius: 0 !important;
  }
</style>
<script>
  export default {
    data: () => ({
      footer_items: [
        {
          cols: 4,
          align:'left',
          title: 'About Us',
          text: [
              'Over the years Yorkshire Bath Resurfacing have developed a unique system of resurfacing most type’s of bath in-situ, irrespective of age or condition.',
              'We use an extremely tough, durable and hard wearing enamel for cast iron and steel baths, basins, pedestals and shower trays etc., either in traditional white or by making use of our professional colour matching service. Simply supply a sample of the colour and we will match it exactly.',
              ' Our specialist colour matching department can reproduce all the latest range of bathroom colours or match obsolete colours from yesteryear. Alternatively, we can transform your coloured bath to traditional White. ',
          ],
          list: []
        },
        {
          cols: 1,
          title: 'Social',
          align:'center',
          list: [],
          icons: [
            {
              icon: 'mdi-facebook',
              link: 'https://www.facebook.com/pages/Yorkshire-Bath-Resurfacing/172884329428790'
            }
          ],
          contact: false,
        },
        {
          cols: 2,
          align:'left',
          title: 'Work We\'ve Done',
          list: [
              'Castle Howard',
              'Blackpool Hilton',
              'Forest and Vale',
              'Royal York',
              'Chatsworth Manor',
              'Aldwalk Manor',
              'Metropole Hotel',
              'Glasgow Hilton',
          ],
          icons: [],
          contact: false,
        },

        {
          cols: 3,
          align:'left',
          title: 'Contact',
          text: [],
          icons: [],
          list: [],
          contact: true
        }
      ]
    }),
    created() {

    },
    watch: {

    },
    methods: {
      openLink(url) {
        window.open(url);
      }
    }
  }
</script>