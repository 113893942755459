<template>
    <v-main>
        <div id="core-view">
            <v-fade-transition mode="out-in">
                <router-view />
            </v-fade-transition>
        </div>
    </v-main>
</template>
<script>
    export default {
        name:'view',
        metaInfo () {
            return {
                title: 'YorkshireBath'
            }
        }
    }
</script>
<style>
    #core-view {
        padding-bottom: 0!important;
    }
</style>
