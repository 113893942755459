<template>
  <div>
    <v-container>
      <v-card width="100%" class="elevation-0">
        <v-app-bar
            class="ma-auto pa-0"
            color="white"
            flat
            :collapse="false"
            :collapse-on-scroll="false"
        >

          <v-app-bar-nav-icon class="d-none"></v-app-bar-nav-icon>

          <v-toolbar-title class="pl-0">
            <h4 class="font-weight-bold">
              Yorkshire <span class="primary--text">Bath</span> Resurfacing
            </h4>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
              class="menu-item-link"
              text
              :class="{'active': $route.name === link.routeName}"
              @click="$router.push({name: link.routeName})"
              v-for="link in links">
            {{ link.text }}
          </v-btn>


        </v-app-bar>
      </v-card>
    </v-container>
  </div>
</template>

<style lang="scss">
  .menu-item-link {
    border-radius: 0px!important;
    text-transform: capitalize!important;

    &:hover {
      border-bottom: 2px solid #7AB317;
    }
  }
  .menu-item-link.active {
    border-bottom: 2px solid #7AB317;
  }
  .v-toolbar__content {
    padding:4px 0!important;
  }
</style>

<script>
  export default {
    data: () => ({
      links: [
        {
          routeName: 'Home',
          text: 'Home'
        },
        {
          routeName: 'About',
          text: 'About'
        },
        {
          routeName: 'Gallery',
          text: 'Gallery'
        },
        {
          routeName: 'Contact',
          text: 'Contact'
        },
      ],
    }),
    created() {

    },
    watch: {

    },
    methods: {

    }
  }
</script>
