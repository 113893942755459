export default [
    {
        path: '/',
        component: (resolve) => import(`@/views/Main/Wrapper.vue`).then(resolve),
        children: [
            {
                path: '',
                name: 'Home',
                meta: {
                    requiresAuth: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Home.vue`).then(resolve),
                }
            },
            {
                path: 'about',
                name: 'About',
                meta: {
                    requiresAuth: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/About.vue`).then(resolve),
                }
            },
            {
                path: 'gallery',
                name: 'Gallery',
                meta: {
                    requiresAuth: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Gallery.vue`).then(resolve),
                }
            },
            {
                path: 'offers',
                name: 'Offers',
                meta: {
                    requiresAuth: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Offers.vue`).then(resolve),
                }
            },
            {
                path: 'contact',
                name: 'Contact',
                meta: {
                    requiresAuth: false
                },
                components: {
                    router_wrapper_main: (resolve) => import(`@/views/Main/Contact.vue`).then(resolve),
                }
            },
        ]
    },
    // {
    //     path: '/c-panel',
    //     component: (resolve) => import(`@/views/CPanel/Wrapper.vue`).then(resolve),
    //     children: [
    //         {
    //             path: 'mortgage-valuations',
    //             name: 'MValuation',
    //             meta: {
    //                 requiresAuth: true
    //             },
    //             components: {
    //                 router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Admins/MortgageValuations.vue`).then(resolve),
    //             }
    //         },
    //     ]
    // },
    {
        path: '*',
        component: (resolve) => import(`@/views/Errors/404.vue`).then(resolve)
    },
];
